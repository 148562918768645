<template>
    <div id="content" class="container">
        <div class="overlay" v-if="img || video" >
            <a href="" class="x-close" @click.prevent="img = ''; video = '';">X</a>
            <img v-if="img" id="gallery-image" @load="gImgDoneLoad = true" :class="{'show': gImgDoneLoad}" :src="img" alt="">
            <video v-else-if="video" width="320" height="240" controls >
                <source :src="video" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
        <!-- <div class="masonary-row"  :class="{'loaded': 'true'}"> -->
        <div class="masonary-row"  :class="{'loaded': initLoad}">
            <masonry
                :cols="{default: 3, 1000: 3, 700: 2, 400: 1}"
                :gutter="0"
  >
            <div class="tile" v-for="m in mediaList">
                <!-- {{m.media_details}} -->
                <a v-if="m.media_type == 'image'" href="" @click.prevent="img = m.media_details.sizes.full.source_url">
                    <img @load="doneLoad" :src="m.media_details.sizes.medium.source_url" alt="">
                </a>
                <a v-else-if="m.media_type == 'file'" href="" @click.prevent="video = m.source_url">
                    <img @load="doneLoad" :src="m._embedded['wp:featuredmedia'][0].source_url" alt="">
                </a>
            </div>
            </masonry> 
        </div>
    </div>
</template>

<script>
import {BASE_URL} from '../config.js';
export default {
    props: [],
    data() {
        return {
            gallery: "" ,
            img: "" ,
            video: "" ,
            media: [],
            mediaList: [],
            totalLoaded: 0,
            gImgDoneLoad: false,
            allImgDoneLoad: false,
            initLoad: false
        }
    },
    beforeMount() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    mounted() {
        this.gallery = this.$route.params.gal || "gallery";
       
        this.img = "";
        var that = this;
        
        this.$root.$on('catsload', function(){
            that.getMedia();
        })
        if (this.$root.categories) {

            this.getMedia();
        }
        // console.log()
        this.totalLoaded = 0;
        
    },
    methods: {
        handleScroll: function(e) {
// console.log( document.body.scrollHeight)
// console.log(window.pageYOffset + window.innerHeight)
            if (Math.round(window.pageYOffset + window.innerHeight) > (document.body.scrollHeight - 200) && this.allImgDoneLoad == true) {
                // this.mediaList.push(this.media.splice(0,6));
                let newArr = this.media.splice(0,6)
                this.mediaList = this.mediaList.concat(newArr)
                this.allImgDoneLoad = false;
            }
        },
        doneLoad: function(e) {
            e.target.classList.add("loaded")
            // (e.path[0].classList.add("loaded") )
            this.totalLoaded++;
            this.allImgDoneLoad = this.totalLoaded == this.mediaList.length;
            if (!this.initLoad) this.initLoad = this.allImgDoneLoad
            console.log(this.totalLoaded)
            
        },
        findObjectByKey :function(array, key, value) {
            for (var i = 0; i < array.length; i++) {
                if (array[i][key] === value) {
                    return array[i];
                }
            }
            return null;
        },
        getMedia: function() {
            var id = this.findObjectByKey(this.$root.categories, 'slug', this.gallery).id;
             var that = this;
            fetch(BASE_URL+'/wp-json/wp/v2/media?categories='+id+'&per_page=40&_embed')
                .then((r) => r.json())
                .then(function(res) {

                    that.media = res;
                    that.mediaList = that.media.splice(0,6);
               console.log(that.mediaList)
               console.log(that.media)
            } );
        }
    },
    watch: {
    '$route' (to, from) {
      console.log(to.params.gal)
      this.totalLoaded = 0;
      this.gallery = to.params.gal;
      this.getMedia();
    },
    img(to, from) {
       
        if (to == "") {
            this.gImgDoneLoad = false;
        }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
    max-width: 1350px;
    padding-left: initial;
    padding-right: initial;
}
.masonary-row {
    opacity: 0;
    transition: opacity .4s;
    margin-top: 70px;
    &.loaded {
        opacity: 1;
        .tile {
            img {
                
                opacity: 0;
            }
        }
    }
    .tile {
        text-align: center;
       
        img {
            margin-top: 40px;
            
            opacity: 0;
            transition: all 1.4s;
            width: 100%;
            &.loaded {
                opacity: 1;
                margin-top: 0px;
            }

        }
    }
}
#gallery-image {
    opacity: 0;
    transition: opacity .5s;
    &.show {
        opacity: 1;
    }
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000CC;
    z-index: 4;
    .x-close {
        position: absolute;
        right: 20px;
        top: 10px;
        color: #FFF;
        font-size: 1.5em;
        &:hover {
            text-decoration: none;
        }
    }
    video {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        background: black;
        height: 80vh;
        margin-top: 10vh;
    }
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 90vw;
        max-height: 90vh;
        
    }
}

</style>